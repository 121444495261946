import React, { Component } from "react";
import { Link } from "gatsby";

export default class Footer extends Component {
  render() {
    const { data } = this.props;
    return (
      <footer class="site-footer">
        <div class="container">
          <div class="row">
            <div class="col-sm-7 col-xs-12">
              <Link to="/">
                {data.contentfulSiteInformation.logo.file.url ? (
                  <img className="footer-logo" src={data.contentfulSiteInformation.logo.file.url} alt="logo" />
                ) : (
                  <span>{data.contentfulSiteInformation.siteName}</span>
                )}
              </Link>
              <p class="text-muted pt-1 pb-3">Durban's First Choice for Luxury Retirement</p>
              <ul class="list-unstyled text-muted">
                <li class="mb-3">
                  <i class="fa fa-map-marker mr-3" aria-hidden="true"></i>
                  <a class="text-muted">{data.contentfulContact.address}</a>
                </li>
                <li class="mb-3">
                  <i class="fa fa-phone mr-3" aria-hidden="true"></i>
                  <a class="text-muted" href={`tel:${data.contentfulContact.phoneNumber}`}>{data.contentfulContact.phoneNumber}</a>
                </li>
                <li class="mb-3">
                  <i class="fa fa-envelope mr-3" aria-hidden="true"></i>
                  <a class="text-muted" href={`mailto:${data.contentfulContact.email}`}>{data.contentfulContact.email}</a>
                </li>
              </ul>
              {/*<div class="col-sm-12">
                <form
                  //action={`https://formspree.io/${this.props.data}`}
                  name="newsletter"
                  method="POST"
                  data-netlify="true"
                >
                    <input type="email" name="email" class="form-control" placeholder="Enter email address" required />
                    <span>
                      <button class="btn btn-primary" type="submit">Newsletter Sign Up</button>
                    </span>
                    <input type="hidden" name="subject" value="Newsletter" />
                    <input type="hidden" name="form-name" value="newsletter" />
                </form>
                </div>*/}
            </div>

            <div class="col-sm-3 col-xs-12">
              <div class="mb-4 mt-4 mt-md-0">
                <h2 class="footer-links">Links</h2>
              </div>
              <ul class="list-unstyled">
                <li>
                  <Link to={`/#About`}>About</Link>
                </li>
                <li>
                  <Link to={`/#Service`}>Service</Link>
                </li>
                <li>
                  <Link to={`/#ActivitiesFacilities`}>Activities & Facilities</Link>
                </li>
                <li>
                  <Link to={`/#Apartments`}>Apartments</Link>
                </li>
                <li>
                  <Link to={`/#Blogs`}>News</Link>
                </li>
                <li>
                  <Link to={`/#Photos`}>Gallery</Link>
                </li>
                <li>
                  <Link to={`/Info`}>Fees</Link>
                </li>
                <li>
                  <Link to={`/#Contact`}>Contact</Link>
                </li>
              </ul>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-sm-7 col-xs-12 order-3 order-md-0">
              <p class="copy-right mb-0 pb-4 pb-md-0">
                Copyright &copy; 2024. All Rights Reserved by
                <a href="http://www.seastarmarketing.co.za/" target="_blank"> Sea Star Marketing - Ballito</a>
              </p>
            </div>
            <div class="col-sm-3 col-xs-12">
              <div className="socials footer-social-centre">
                <ul>
                  <li>
                    <a
                      className="fab fa-facebook-f"
                      href={data.contentfulAbout.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    ></a>
                  </li>
                  <li>
                    <a
                      className="fab fa-instagram"
                      href={data.contentfulAbout.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
